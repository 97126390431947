<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <h3 class="has-text-weight-semibold is-size-4 mb-2">
      <b-icon icon="sliders-h"></b-icon>
      Opciones del medidor
    </h3>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-4">
        <div class="columns is-flex-wrap-wrap">
          <div class="column is-12">
            <input-base
              rules="required"
              label="Nombre"
              maxlength="150"
              placeholder="Título del bloque"
              v-model="form.nombre"
              focus
            />
          </div>
          <div class="column is-12">
            <select-base
              rules="required"
              label="Ancho del bloque"
              v-model="form.ancho"
              :options="anchosBloque"
              expanded
            />
          </div>
          <div class="column is-12" >
            <date-picker-base
              rules="required"
              label="Mostrar entre las fechas"
              range
              v-model="form.rango_fechas"
            />
          </div>
          <div class="column is-12">
            <switch-base
              label="Aceptar filtros externos?"
              v-model="form.filtros_externos"
            />
          </div>
        </div>
      </div>
      <div class="column is-4">
        <p>Arrástra el campo al cuadrante correspondiente:</p>
        <TreeCampos />
      </div>
      <div class="column is-4">
        <TreeUsando v-model="form.usando" :tipo="form.medidor" />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="$emit('oncancel')"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );
// 
import TreeCampos from "../campos/TreeCampos.vue"
import TreeUsando from "../usando/TreeUsando.vue"
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  components: {
    TreeCampos,
    TreeUsando,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    name_store(){
      return `form_${this.$route.meta.form}`
    },
    anchosBloque(){
      return this.$store.getters[`${name_store}/anchosBloque`]
    },
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  watch:{
    "form.id":{
      handler(e){
        if(!e) this.defaultData()
      },
      immediate: true,
    }
  },
  methods: {
    defaultData(){
      this.$set(this.form, "nombre", `Medidor sin título`)
      this.$set(this.form, "ancho", 2)
      this.$set(this.form, "rango_fechas", [new Date, new Date])
      this.$set(this.form, "usando", [])
      this.$set(this.form, "filtros_externos", true)
      this.$set(this.form, "tipo", "medidor")
      if(this.$refs.observer){
        this.$refs.observer.reset()
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      if(!this.form.usando.length){
        return this.$store.dispatch(
          "app/setToastWarning",
          `Arraste almenos un registro a la sección "USANDO"`
        );
      }
      // 
      this.loading = true;
      const row = await this.$store.dispatch(`${this.name_store}/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar el panel: "${row.nombre}"`
        );
        if(!this.form.id) {
          this.defaultData()
        }
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>