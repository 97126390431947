<template>
  <div>
    <title-bar
      :items="title_page"
    >
    </title-bar>
    <div class="box">
      <Form 
        v-model="row"
        @oncancel="$router.go(-1)"
        @onsuccess="$router.go(-1)" />
    </div>
  </div>
</template>

<script>
import Form from "../components/forms/MedidorForm";
export default {
  components: {
    Form,
  },
  data(){
    return {
      row: {}
    }
  },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    panel(){
      return this.$store.getters[`form_1623469803663/row`]
    },
    title_page(){
      const txt =  this.panel ? `Editar` : `Nuevo`
      return this.$store.getters[`form_1617124496702/title_bar`]({
        title: `${txt} panel tipo ${this.$route.meta.title}`
      })
    }
  },
  async created(){
    if(this.panel){
      this.row = {
        id: this.panel.id,
        nombre: this.panel.nombre,
        ancho: this.panel.attrs.ancho,
        rango_fechas: this.panel.attrs.rango_fechas,
        usando: this.panel.attrs.usando,
        tipo: this.panel.attrs.tipo,
        filtros_externos: this.panel.attrs.filtros_externos || false,
      }
    }
    this.$set(this.row, "id_padre", this.$route.params.id)
  },
};
</script>